import Vue from 'vue';

import { Notification } from 'element-ui';

import VueRouter from 'vue-router';
import Calculate from '../views/Calculate.vue';
import EquipmentManagement from '../views/EquipmentManagement.vue';
import FirstManage from '../views/FirstManage.vue';
import Home from '../views/Home.vue';
import Log from '../views/Log.vue';
import Login from '../views/Login.vue';
import Master from '../views/Master.vue';
import MaterialManagementOld from '../views/MaterialManagement.vue';
import Register from '../views/Register.vue';
import RegisterManage from '../views/RegisterManage.vue';
import UserManage from '../views/UserManage.vue';


import HeatTreatmentOptimization from '../views/HeatTreatmentOptimization.vue';

import NewHome from '../views/NewHome.vue';

//主页
import Main from '../views/Main.vue';
//忘记密码
import PasswordForget from '../views/PasswordForget.vue';
//单位管理
import CompanyManage from '../views/CompanyManage.vue';

import Helper from '../views/Helper.vue';

//材料物性管理
import CreateMaterial from '../views/MaterialProperties/CreateMaterial.vue';
import MaterialManagement from '../views/MaterialProperties/MaterialManagement.vue';

//工艺参数管理
import CreateProcess from '../views/ProcessProperties/CreateProcess/index.vue';
import ProcessPropertiesManagement from '../views/ProcessProperties/ProcessPropertiesManagement.vue';

import ModelList from '../views/ModelList';
//帮助指南
import { GetTime } from '../api/api';
import AuthorityManage from '../views/AuthorityManage';
import ToComputer from '../views/ToComputer';
import ToCoperate from '../views/ToCoperate';
import ToTeach from '../views/ToTeach';
import ToView from '../views/ToView';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Login',
		component: Login,
	},
	{
		path: '/Master',
		name: 'Master',
		component: Master,
		meta: {
			title: '主页',
		},
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		children: [
			{
				path: 'main',
				name: 'Main',
				component: Main,
			},
			{
				path: 'calculate',
				name: 'Calculate',
				component: Calculate,
			},

			{
				path: 'EquipmentManagement',
				name: 'EquipmentManagement',
				component: EquipmentManagement,
			},
			{
				path: 'MaterialManagement',
				name: 'MaterialManagement',
				component: MaterialManagementOld,
			},
			{
				path: 'FirstManage',
				name: 'FirstManage',
				component: FirstManage,
			},
			// {
			//     path: 'OptimizationManagement',
			//     name: 'OptimizationManagement',
			//     component: OptimizationManagement
			// },
			{
				path: 'HeatTreatmentOptimization',
				name: 'HeatTreatmentOptimization',
				component: HeatTreatmentOptimization,
			},
		],
	},
	{
		path: '/Newhome',
		name: 'Newhome',
		component: NewHome,
		beforeEnter: (to, from, next) => {
			console.log(to.path);
			if (to.path == '/newhome/Calculate') {
				let name = localStorage.getItem('name');
				GetTime(name).then((res) => {
					console.log(res);
					if (res.data) {
						next();
					} else {
						Notification({
							title: '无权限访问',
							message:
								'您好，您所在单位无权限访问该页面，请联系管理员进行开通、续费操作',
							type: 'warning',
						});
					}
				});
			}
			if (to.path == '/newhome/Usermanage') {
				let name = localStorage.getItem('name');
				GetTime(name).then((res) => {
					console.log(res);
					if (res.data) {
						next();
					} else {
						Notification({
							title: '无权限访问',
							message: '您好，仅管理员可以进行该操作',
							type: 'warning',
						});
					}
				});
			}
		},
		children: [
			{
				path: 'CreateMaterial',
				name: '创建材料物性文件',
				component: CreateMaterial,
			},
			{
				path: 'MaterialManagement',
				name: '材料物性列表',
				component: MaterialManagement,
			},
			{
				path: 'ProcessPropertiesManagement',
				name: '工艺参数列表',
				component: ProcessPropertiesManagement,
			},
			{
				path: 'CreateProcess',
				name: '创建工艺参数文件',
				component: CreateProcess,
			},
			{
				path: 'ModelList',
				name: '模型列表',
				component: ModelList,
			},
			{
				path: 'calculate',
				name: 'COSMAP',
				component: Calculate,
			},
			{
				path: 'bearing',
				name: '轴承智能模拟制造',
				component: Calculate,
			},
			{
				path: 'cogs',
				name: '齿轮智能模拟制造',
				component: Calculate,
			},

			{
				path: 'usermanage',
				name: '用户管理',
				component: UserManage,
			},
			{
				path: 'CompanyManage',
				name: '单位管理',
				component: CompanyManage,
			},
			{
				path: 'registerManage',
				name: '申请管理',
				component: RegisterManage,
			},
			{
				path: 'Log',
				name: '安全日志',
				component: Log,
			},
		],
	},
	{
		path: '/Helper',
		name: 'Helper',
		component: Helper,
		redirect: '/ToView',
		children: [
			{
				path: '/ToView',
				name: 'ToView',
				component: ToView,
			},
			{
				path: '/ToComputer',
				name: 'ToComputer',
				component: ToComputer,
			},
			{
				path: '/ToCoperate',
				name: 'ToCoperate',
				component: ToCoperate,
			},
			{
				path: '/ToTeach',
				name: 'ToTeach',
				component: ToTeach,
			},
		],
	},
	{
		path: '/PasswordForget',
		name: 'PasswordForget',
		component: PasswordForget,
	},
	{
		path: '/AuthorityManage',
		name: 'AuthorityManage',
		component: AuthorityManage,
	},
	{
		path: '/about',
		name: 'About',
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/About.vue'),
	},

	{
		path: '/register',
		name: 'Register',
		component: Register,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});
router.beforeEach((to, from, next) => {
	let token = localStorage.getItem('token');
	if (
		to.name != 'Login' &&
		to.name != 'PasswordForget' &&
		to.name != 'Register' &&
		!token
	) {
		next({ name: 'Login' });
	} else {
		next();
	}
});
export default router;
