<template>
  <el-dialog title="参数输入" :visible.sync="dialogVisible" width="90%" @close="closeDialog">
    <el-form label-width="120px" :model="form">
      <el-form-item label="实验名称" :label-width="formLabelWidth">
        <el-input v-model="form.name" autocomplete="off" placeholder="实验名称"></el-input>
      </el-form-item>
      
      <el-form-item label="分批数量">
        <el-input v-model="batchCount" placeholder="输入分批数量" type="number"></el-input>
      </el-form-item>

      <!-- 动态阶段列表 -->
      <div v-for="(stage, index) in stages" :key="index" class="stage">
        <div class="stage-header">
          <h3>{{ stage.name }}</h3>
          <el-button 
            v-if="canDeleteStage(index)" 
            type="danger" 
            size="mini" 
            icon="el-icon-delete"
            @click="deleteStage(index)"
          >删除阶段</el-button>
        </div>

        <el-row :gutter="20">
          <!-- 如果是固定温度的阶段 -->
          <template v-if="!stage.hasRange">
            <el-col :span="6">
              <el-form-item label="温度℃">
                <el-input v-model="stage.data.temperature" placeholder="输入温度" type="number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="时间min">
                <el-input v-model="stage.data.time" placeholder="输入时间" type="number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="碳浓度%">
                <el-input v-model="stage.data.carbonConcentration" placeholder="输入碳浓度" type="number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="氮浓度%">
                <el-input v-model="stage.data.nitrogenConcentration" placeholder="输入氮浓度" type="number"></el-input>
              </el-form-item>
            </el-col>
          </template>

          <!-- 如果是范围温度的阶段 -->
          <template v-else>
            <el-col :span="6">
              <el-form-item label="温度区间℃">
                <div class="temperature-range">
                  <el-input v-model="stage.data.temperatureMin" placeholder="最低温度" type="number"></el-input>
                  <span class="range-separator">-</span>
                  <el-input v-model="stage.data.temperatureMax" placeholder="最高温度" type="number"></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="时间区间min">
                <div class="temperature-range">
                  <el-input v-model="stage.data.timeMin" placeholder="最短时间" type="number"></el-input>
                  <span class="range-separator">-</span>
                  <el-input v-model="stage.data.timeMax" placeholder="最长时间" type="number"></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="碳浓度区间%">
                <div class="temperature-range">
                  <el-input v-model="stage.data.carbonConcentrationMin" placeholder="最低碳浓度" type="number"></el-input>
                  <span class="range-separator">-</span>
                  <el-input v-model="stage.data.carbonConcentrationMax" placeholder="最高碳浓度" type="number"></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="氮浓度区间%">
                <div class="temperature-range">
                  <el-input v-model="stage.data.nitrogenConcentrationMin" placeholder="最低氮浓度" type="number"></el-input>
                  <span class="range-separator">-</span>
                  <el-input v-model="stage.data.nitrogenConcentrationMax" placeholder="最高氮浓度" type="number"></el-input>
                </div>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </div>

      <!-- 添加新阶段按钮 -->
      <div class="add-stage">
        <el-button type="primary" @click="showAddStageDialog">添加新阶段</el-button>
      </div>

      <!-- 模型参数文件 -->
      <el-form-item label="Shape Data" :label-width="formLabelWidth">
        <el-col :span="22">
          <el-input v-model="customerCheckShapeDataName" :disabled="true"></el-input>
        </el-col>
      </el-form-item>

      <!-- 材料文件 -->
      <el-form-item label="Matirial Data" :label-width="formLabelWidth">

        <el-col :span="22">
          <el-input v-model="customerCheckMaterialName" :disabled="true"></el-input>
        </el-col>

      </el-form-item>
    </el-form>

    <!-- 添加新阶段的对话框 -->
    <el-dialog
      title="添加新阶段"
      :visible.sync="addStageDialogVisible"
      append-to-body
      width="30%"
    >
      <el-form :model="newStage">
        <el-form-item label="阶段名称">
          <el-input v-model="newStage.name" placeholder="请输入阶段名称"></el-input>
        </el-form-item>
        <el-form-item label="参数类型">
          <el-select v-model="newStage.hasRange" placeholder="请选择参数类型">
            <el-option label="固定参数" :value="false"></el-option>
            <el-option label="范围参数" :value="true"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addStageDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addNewStage">确 定</el-button>
      </span>
    </el-dialog>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="submitData">确认生成</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { changePos, AddCalculateandPos } from '../api/api';

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
    },
    customerCheckShapeDataName: {
      type: String,
      required: true,
    },
    customerCheckMaterialName: {
      type: String,
      required: true,
    },
    customerCheckShapeData: {
      type: Object,
    },
    customerCheckMaterial: {
      type: Object,
    }

  },
  data() {
    return {
      formLabelWidth: '120px',

      batchCount: 1,
      stages: [
        {
          name: '预热阶段',
          hasRange: false,
          required: true,
          data: { 
            temperature: '850', 
            time: '30', 
            carbonConcentration: '0',
            nitrogenConcentration: '0' 
          }
        },
        {
          name: '渗碳阶段',
          hasRange: true,
          required: false,
          data: { 
            temperatureMin: '850', 
            temperatureMax: '870', 
            timeMin: '30', 
            timeMax: '', 
            carbonConcentrationMin: '0.86', 
            carbonConcentrationMax: '',
            nitrogenConcentrationMin: '0',
            nitrogenConcentrationMax: '' 
          }
        },
        {
          name: '扩散阶段',
          hasRange: true,
          required: false,
          data: { 
            temperatureMin: '850', 
            temperatureMax: '', 
            timeMin: '30', 
            timeMax: '', 
            carbonConcentrationMin: '0.8', 
            carbonConcentrationMax: '',
            nitrogenConcentrationMin: '0',
            nitrogenConcentrationMax: '' 
          }
        },
        {
          name: '保温阶段',
          hasRange: false,
          required: false,
          data: { 
            temperature: '800', 
            time: '30', 
            carbonConcentration: '0.76', 
            nitrogenConcentration: '0.76' 
          }
        }
      ],
      addStageDialogVisible: false,
      newStage: {
        name: '',
        hasRange: false
      },
      form: {
        name: "",
        nasPath: '',
        posPath: '',
        datPath: '',
        configuration: [],
        PosRequest: [],
      },
    };
  },
  methods: {
    closeDialog() {
      this.$emit('update:dialogVisible', false);
    },
    getValues(min, max, count) {
      const minVal = parseFloat(min);
      const maxVal = parseFloat(max);
      
      if (isNaN(minVal) || isNaN(maxVal) || minVal === maxVal) {
        return [minVal || 0];
      }
      
      const step = (maxVal - minVal) / (count - 1);
      return Array.from({length: count}, (_, i) => 
        parseFloat((minVal + i * step).toFixed(2))
      );
    },
    calculateTotalCombinations() {
      const batchCount = parseInt(this.batchCount) || 1;
      let totalRangeCount = 0;

      // 遍历所有阶段，统计区间数量
      this.stages.forEach(stage => {
        if (!stage.hasRange) return;
        
        const data = stage.data;
        // 检查每个参数是否是真正的区间（最大值和最小值不相等）
        if (data.temperatureMin !== data.temperatureMax && data.temperatureMax) totalRangeCount++;
        if (data.timeMin !== data.timeMax && data.timeMax) totalRangeCount++;
        if (data.carbonConcentrationMin !== data.carbonConcentrationMax && data.carbonConcentrationMax) totalRangeCount++;
        if (data.nitrogenConcentrationMin !== data.nitrogenConcentrationMax && data.nitrogenConcentrationMax) totalRangeCount++;
      });

      // 计算总组合数：分批数量的区间数量次方
      return Math.pow(batchCount, totalRangeCount);
    },
    // 生成实验名称的新方法
    generateExperimentName(baseName, combination) {
      let variantParts = [];
      
      // 遍历所有阶段，找出区间值的变化
      combination.forEach(stageData => {
        const { stage, temperature, time, carbonConcentration, nitrogenConcentration } = stageData;
        
        // 查找对应的原始阶段配置
        const originalStage = this.stages.find(s => s.name === stage);
        if (!originalStage?.hasRange) return;
        
        // 检查每个参数是否是区间值，如果是则添加到变体名称中
        if (originalStage.data.temperatureMin !== originalStage.data.temperatureMax) {
          variantParts.push(`${stage}-T${temperature}`);
        }
        if (originalStage.data.timeMin !== originalStage.data.timeMax) {
          variantParts.push(`${stage}-t${time}`);
        }
        if (originalStage.data.carbonConcentrationMin !== originalStage.data.carbonConcentrationMax) {
          variantParts.push(`${stage}-C${carbonConcentration}`);
        }
        if (originalStage.data.nitrogenConcentrationMin !== originalStage.data.nitrogenConcentrationMax) {
          variantParts.push(`${stage}-N${nitrogenConcentration}`);
        }
      });

      // 如果没有变体，返回基础名称
      if (variantParts.length === 0) {
        return `${baseName}_默认参数`;
      }

      // 组合名称
      return `${baseName}_${variantParts.join('_')}`;
    },
    async submitData() {
      if (!this.validateForm()) return;
      if (!this.form.name) {
        this.$message.error('请输入基础实验名称！');
        return;
      }

      // 检查组合总数
      const totalCombinations = this.calculateTotalCombinations();
      if (totalCombinations > 10) {
        this.$message.error(`组合总数(${totalCombinations})超过最大限制(10)，请减少区间数量或分批数量！`);
        return;
      }
      
      try {
        const batchCount = parseInt(this.batchCount) || 1;
        let allCombinations = [[]]; // 初始化一个空组合

        // 遍历每个阶段
        for (const stage of this.stages) {
          const newCombinations = [];
          
          // 为每个现有组合添加当前阶段的值
          for (const existingCombo of allCombinations) {
            const stageValues = {
              temperature: [],
              time: [],
              carbonConcentration: [],
              nitrogenConcentration: []
            };

            if (stage.hasRange) {
              // 对于区间值，生成多个值
              stageValues.temperature = this.getValues(stage.data.temperatureMin, stage.data.temperatureMax, batchCount);
              stageValues.time = this.getValues(stage.data.timeMin, stage.data.timeMax, batchCount);
              stageValues.carbonConcentration = this.getValues(stage.data.carbonConcentrationMin, stage.data.carbonConcentrationMax, batchCount);
              stageValues.nitrogenConcentration = this.getValues(stage.data.nitrogenConcentrationMin, stage.data.nitrogenConcentrationMax, batchCount);
            } else {
              // 对于固定值，使用单个值
              stageValues.temperature = [parseFloat(stage.data.temperature) || 0];
              stageValues.time = [parseFloat(stage.data.time) || 0];
              stageValues.carbonConcentration = [parseFloat(stage.data.carbonConcentration) || 0];
              stageValues.nitrogenConcentration = [parseFloat(stage.data.nitrogenConcentration) || 0];
            }

            // 生成当前阶段的所有可能组合
            for (const temp of stageValues.temperature) {
              for (const time of stageValues.time) {
                for (const carbon of stageValues.carbonConcentration) {
                  for (const nitrogen of stageValues.nitrogenConcentration) {
                    newCombinations.push([...existingCombo, {
                      stage: stage.name,
                      temperature: temp,
                      time: time,
                      carbonConcentration: carbon,
                      nitrogenConcentration: nitrogen
                    }]);
                  }
                }
              }
            }
          }
          allCombinations = newCombinations;
        }

        // 提交所有组合到后端时使用新的命名方法
        for (let i = 0; i < allCombinations.length; i++) {
          const combination = allCombinations[i];
          const experimentName = this.generateExperimentName(this.form.name, combination);
          
          const formData = {
            ...this.form,
            name: experimentName,
            nasPath: this.customerCheckShapeData.FilePath,
            datPath: this.customerCheckMaterial.FilePath,
            configuration: Array(41).fill(true),
            PosRequest: { Steps: combination }
          };

          await AddCalculateandPos(formData);
          this.$message.info(`正在处理: ${i + 1}/${allCombinations.length}`);
        }

        this.$message.success('批量计算成功！');
        this.closeDialog();
      } catch (error) {
        console.error('批量计算错误:', error);
        this.$message.error('批量计算失败！');
      }
    },

    // 表单验证
    validateForm() {
      if (!this.batchCount || isNaN(parseInt(this.batchCount)) || parseInt(this.batchCount) < 1) {
        this.$message.error(`分批数量必须填写，且为大于0的整数！`);
        return false;
      }

      for (const stage of this.stages) {
        const stageData = stage.data;
        if (!stageData) continue;

        const { temperature, temperatureMin, temperatureMax, time, timeMin, timeMax, carbonConcentration, carbonConcentrationMin, carbonConcentrationMax, nitrogenConcentration, nitrogenConcentrationMin, nitrogenConcentrationMax } = stageData;

        if (
          !temperature &&
          !temperatureMin &&
          !temperatureMax &&
          !time &&
          !timeMin &&
          !timeMax &&
          !carbonConcentration &&
          !carbonConcentrationMin &&
          !carbonConcentrationMax &&
          !nitrogenConcentration &&
          !nitrogenConcentrationMin &&
          !nitrogenConcentrationMax
        ) {
          this.$message.error(`${stage.name} 阶段的至少一个参数必须填写！`);
          return false;
        }
      }

      return true;
    },

    // 下载文件方法
    downloadFile(data, filename) {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    canDeleteStage(index) {
      return !this.stages[index].required;
    },

    deleteStage(index) {
      if (!this.stages[index].required) {
        this.stages.splice(index, 1);
      }
    },

    showAddStageDialog() {
      this.newStage = {
        name: '',
        hasRange: false
      };
      this.addStageDialogVisible = true;
    },

    addNewStage() {
      if (!this.newStage.name.trim()) {
        this.$message.error('请输入阶段名称');
        return;
      }

      const newStageData = {
        name: this.newStage.name.trim(),
        hasRange: this.newStage.hasRange,
        required: false,
        data: this.newStage.hasRange ? {
          temperatureMin: '',
          temperatureMax: '',
          timeMin: '',
          timeMax: '',
          carbonConcentrationMin: '',
          carbonConcentrationMax: '',
          nitrogenConcentrationMin: '',
          nitrogenConcentrationMax: ''
        } : {
          temperature: '',
          time: '',
          carbonConcentration: '',
          nitrogenConcentration: ''
        }
      };

      this.stages.push(newStageData);
      this.addStageDialogVisible = false;
    },

  },
};
</script>

<style scoped>
.stage-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.add-stage {
  text-align: center;
  margin: 20px 0;
}

.dialog-footer {
  text-align: right;
}

.temperature-range {
  display: flex;
  align-items: center;
  width: 100%;
}

.temperature-range .el-input {
  width: 45%;
}

.range-separator {
  margin: 0 5%;
  text-align: center;
}
</style>