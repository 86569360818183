import axios from 'axios';

// let BaseUrl = 'http://localhost:5000/api/';

//let BaseUrl = 'http://192.168.31.19:5000/api/'

// let BaseUrl = 'http://210.170.55.210:5002/api/';

//let BaseUrl = 'http://210.170.55.210:5002/api/';

// let BaseUrl = 'http://2u871y8625.oicp.vip:5000/api/';

// let BaseUrl = 'http://imsht.ddns.net:5000/api/'

let BaseUrl = 'http://www.imsht.com:5002/api/'
export const Url = BaseUrl;

export const changePos = (params) => {
	console.log(params); // 打印参数，便于调试
	return axios({
		method: 'POST',
		url: BaseUrl + 'pos/changePos', // API路径
		data: params, // 发送表单数据
		responseType: 'blob', // 服务器返回的是文件
	})
		.then((res) => res.data) // 返回数据
		.catch((error) => console.log('Error:', error)); // 捕获错误
};

export const AddHelper = (params) => {
	console.log(params);
	return axios({
		method: 'Post',
		url: BaseUrl + 'News',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SelectHelper = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'News/Pages',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const EditHelper = (params) => {
	console.log(params);
	return axios({
		method: 'Put',
		url: BaseUrl + 'News',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const DeleteHelper = (params) => {
	console.log(params);
	return axios({
		method: 'Delete',
		url: BaseUrl + 'News',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const Login = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'Auth',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};
//以下代码由T4模板生成
export const SelectUser = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'User/Pages',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SelectCompanyUser = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'User/CompanyPages',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SelectRegisterUser = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'User/RegisterManage',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const OrderHistory = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'Order/History',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const AddUser = (params) => {
	console.log(params);
	return axios({
		method: 'Post',
		url: BaseUrl + 'User',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const RegisterUser = (params) => {
	console.log(params);
	return axios({
		method: 'Post',
		url: BaseUrl + 'User/Register',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const EditUser = (params) => {
	console.log(params);
	return axios({
		method: 'Put',
		url: BaseUrl + 'User',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const DeleteUser = (params) => {
	console.log(params);
	return axios({
		method: 'Delete',
		url: BaseUrl + 'User',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

//同意加入申请
export const AgreeUser = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'User/Agree',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SendEmailToRegister = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'User/SendEmailToRegister',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SendEmailToPasswordForget = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'User/SendEmailToPasswordForget',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const RegisterEmailValidation = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'User/RegisterEmailValidation',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};
export const GetPassWord = (params) => {
	console.log(params);
	return axios({
		method: 'Post',
		url: BaseUrl + 'User/PasswordForget',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SelectCompany = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'Company/Pages',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SelectAllCompany = () => {
	return axios({
		method: 'Get',
		url: BaseUrl + 'Company/All',
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const AddCompany = (params) => {
	console.log(params);
	return axios({
		method: 'Post',
		url: BaseUrl + 'Company',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const EditCompany = (params) => {
	console.log(params);
	return axios({
		method: 'Put',
		url: BaseUrl + 'Company',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const DeleteCompany = (params) => {
	console.log(params);
	return axios({
		method: 'Delete',
		url: BaseUrl + 'Company',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

//添加实验
export const AddCalculate = (params) => {
	console.log(params);
	let token = localStorage.getItem('token');
	return axios({
		headers: {
			Authorization: 'Bearer ' + token,
		},
		method: 'Post',
		url: BaseUrl + 'Calculate',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};
//添加实验并生成Pos文件
export const AddCalculateandPos = (params) => {
	console.log(params);
	let token = localStorage.getItem('token');
	return axios({
		headers: {
			Authorization: 'Bearer ' + token,
		},
		method: 'Post',
		url: BaseUrl + 'Calculate/BatchCalculate',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};
//获取所有实验
export const SelectCalculate = (params) => {
	let token = localStorage.getItem('token');
	return axios({
		headers: {
			Authorization: 'Bearer ' + token,
		},
		method: 'Get',
		url: BaseUrl + 'Calculate/Pages',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

//获取日志
export const GetCaculateLog = (params) => {
	let token = localStorage.getItem('token');
	return axios({
		headers: {
			Authorization: 'Bearer ' + token,
		},
		method: 'Get',
		url: BaseUrl + 'Calculate/GetCaculateLog?id=' + params,
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

//获取计算结果
export const GetCaculateResult = (params) => {
	let token = localStorage.getItem('token');
	return axios({
		headers: {
			Authorization: 'Bearer ' + token,
		},
		method: 'get',
		url: params,
		responseType: 'blob',
	})
		.then((res) => res)
		.catch((error) => console.log(error));
};

export const SelectLog = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'Log/Pages',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SelectEquipment = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'Equipment/Pages',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SelectAllEquipment = () => {
	return axios({
		method: 'Get',
		url: BaseUrl + 'Equipment/All',
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const AddEquipment = (params) => {
	console.log(params);
	return axios({
		method: 'Post',
		url: BaseUrl + 'Equipment',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const EditEquipment = (params) => {
	console.log(params);
	return axios({
		method: 'Put',
		url: BaseUrl + 'Equipment',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const DeleteEquipment = (params) => {
	console.log(params);
	return axios({
		method: 'Delete',
		url: BaseUrl + 'Equipment',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SelectMaterial = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'Material/Pages',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SelectAllMaterial = () => {
	return axios({
		method: 'Get',
		url: BaseUrl + 'Material/All',
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const AddMaterial = (params) => {
	console.log(params);
	return axios({
		method: 'Post',
		url: BaseUrl + 'Material',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const EditMaterial = (params) => {
	console.log(params);
	return axios({
		method: 'Put',
		url: BaseUrl + 'Material',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const DeleteMaterial = (params) => {
	console.log(params);
	return axios({
		method: 'Delete',
		url: BaseUrl + 'Material',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};
export const SelectProcessProperties = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'ProcessProperties/Pages',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SelectAllProcessProperties = () => {
	return axios({
		method: 'Get',
		url: BaseUrl + 'ProcessProperties/All',
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const AddProcessProperties = (params) => {
	console.log(params);
	return axios({
		method: 'Post',
		url: BaseUrl + 'ProcessProperties',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const EditProcessProperties = (params) => {
	console.log(params);
	return axios({
		method: 'Put',
		url: BaseUrl + 'ProcessProperties',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const DeleteProcessProperties = (params) => {
	console.log(params);
	return axios({
		method: 'Delete',
		url: BaseUrl + 'ProcessProperties',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SaveProcessPropertiesToServer = (data) => {
	return axios({
		method: 'Post',
		url: BaseUrl + 'ProcessProperties/CreateProcessProperties',
		data: data,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SelectHeatTreatment = (params) => {
	console.log(params);
	return axios({
		method: 'POST',
		url: BaseUrl + 'ProcessProperties/Search',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SelectShapeData = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'ShapeData/Pages',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const SelectAllShapeData = () => {
	return axios({
		method: 'Get',
		url: BaseUrl + 'ShapeData/All',
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const toword = () => {
	return axios({
		method: 'Get',
		url: BaseUrl + 'Document/WordExport',
	})
		.then((res) => res)
		.catch((error) => console.log(error));
};
// export const SelectAllShapeData = () => {
//     return axios({
//         method: 'Get',
//         url: BaseUrl + 'ShapeData/All',
//     }).then(res => res.data).catch(error => console.log(error));
// }

export const AddShapeData = (params) => {
	console.log(params);
	return axios({
		method: 'Post',
		url: BaseUrl + 'ShapeData',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const EditShapeData = (params) => {
	console.log(params);
	return axios({
		method: 'Put',
		url: BaseUrl + 'ShapeData',
		data: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const DeleteShapeData = (params) => {
	console.log(params);
	return axios({
		method: 'Delete',
		url: BaseUrl + 'ShapeData',
		params: params,
	})
		.then((res) => res.data)
		.catch((error) => console.log(error));
};

export const GetType = (params) => {
	console.log('api:' + params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'User/V_type',
		params: {
			name: params,
		},
	})
		.then((res) => res)
		.catch((error) => console.log(error));
};

export const GetTime = (params) => {
	console.log(params);
	return axios({
		method: 'Get',
		url: BaseUrl + 'User/V_time',
		params: {
			name: params,
		},
	})
		.then((res) => res)
		.catch((error) => console.log(error));
};
